<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false" v-model="global.orgId" @change="handleChange"
                         size='small' style="margin-right: 20px"></el-cascader>
            <el-button plain size='small' type="primary" @click="synRegisterd">同步挂号科室</el-button>
            <el-button plain size='small' class='fr' @click="load">刷新</el-button>
        </div>

        <el-table :data="departmentTableData" stripe :height="tableHeight" style="width: 100%"
                  row-key="depId" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
            <el-table-column type="index" width="50" align="center" />
            <el-table-column prop="hospitalDepId" label="科室ID" width="300" align="center" />
            <el-table-column prop="depName" label="科室名称" align="center" />
            <el-table-column prop="depCode" label="科室代码" align="center" />
            <el-table-column prop="isRegistered" label="名医坐诊科室" :formatter="formatRegister" align="center"></el-table-column>
        </el-table>
    </div>
</template>
<script>
    import uploadImg from '@/components/uploadImg/uploadImg'
    class HospitalConfig {
        constructor() {
            this.address = null;
            this.hospitalId = null;
            this.imageUrl = null;
            this.introduction = null;
            this.isRegistered = null;
            this.isConsult = null;
            this.level = null;
            this.latitude = null;
            this.longitude = null;
            this.name = null;
            this.nature = null;
            this.orgId = null;
            this.phone = null;
            this.website = null;

        }

        reset() {
            this.constructor();
        }
    }

    class Doctor {
        constructor() {
            this.doctorId = null;
            this.doctorName = null;
            this.birthday = null;
            this.title = null;
            this.introduction = null;
            this.headUrl = null;
            this.showHeadUrl = null;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        data() {
            return {
                doctorList: [], //专家列表
                tabIndex: "0",
                hospitalConfig: new HospitalConfig(),
                departmentTableData: [],
                tableHeight: document.documentElement.clientHeight - 180,
            };
        },
        components: {uploadImg},
        computed: {
        },
        methods: {
            formatRegister(row, column) {
                if(1 == row.isRegistered) {
                    return '是';
                } else {
                    return '否';
                }
            },
            async synRegisterdRequest(){
                let data = {
                    hospitalId: this.hospitalConfig.hospitalId
                };
                let res = await this.$http.post('/sys/syncRegisteredDep',data);
                if(res.state){
                    this.$message.success("同步挂号科室成功");
                    this.init()
                }else{
                    this.$message.error("同步挂号科室失败");
                }
            },
            synRegisterd(){
                this.$confirm('确定同步挂号科室信息？', '提示', {}).then(() => {
                    this.synRegisterdRequest();
                });
            },
            pageSizeChange(val) {
                this.page.reset();
                this.page.pageSize = val;
                this.load();
            },
            pageCurrentChange(val) {
                this.page.currentPage = val;
                this.load();
            },
            formatTitle(row, column) {
                return this.getname('title', row.title);
            },

            formatSex(row, column) { //格式化性别
                return this.getname('sex', row.gender)
            },

            uploadImgA(e){
                this.uploadDlgVisibleA=true;
                this.$refs['uploadImgA'].uploadImg(e);
            },
            hideA(){
                this.$refs['uploadInputA'].value=null;
                this.uploadDlgVisibleA=false;
            },
            handleSuccessImgA(data){
                this.hospitalConfig.imageUrl = data;
                this.$message.success('上传成功！');
                this.hideA();
            },

            uploadImgB(e){
                this.uploadDlgVisibleB=true;
                this.$refs['uploadImgB'].uploadImg(e);
            },
            hideB(){
                this.$refs['uploadInputB'].value=null;
                this.uploadDlgVisibleB=false;
            },
            handleSuccessImgB(data){
                this.doctor.headUrl = data;
                this.$message.success('上传成功！');
                this.hideB();
            },
            async getHospitalInfo() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let res = await this.$http.post('/sys/getHospitalInfo', {orgId: orgId});
                if (res.state) {
                    if (res.data) {
                        Object.assign(this.hospitalConfig, res.data);
                        this.getDepartment();
                    }
                }
            },
            async getDepartment() {
                this.departmentTableData = [];
                let res = await this.$http.post('/sys/getHospitalDep',{hospitalId: this.hospitalConfig.hospitalId});
                if(res.state){
                    this.departmentTableData = res.data;
                }else{
                    this.$message.error(res);
                }
            },
            load() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                if (this.tabIndex == "0") {
                    this.getHospitalInfo();
                } else if (this.tabIndex == "1") {
                    this.getHospitalDoctor();
                }
            },
            handleChange(val) {
                if (val) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.isDisabled = false;
                    this.hospitalConfig.reset();
                    this.getHospitalInfo();
                    this.load()
                }
            },
            async updHospitalInfo() {
                let res = await this.$http.post('/sys/updHospitalInfo', this.hospitalConfig);
                if (res.state) {
                    this.$message.success('保存成功');
                    this.getHospitalInfo();
                }
            },
            submit() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.updHospitalInfo();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async getHospitalDoctor() {
                if(!this.hospitalConfig.hospitalId){
                    this.$message.warning('请先选择机构并配置医院信息');
                    return;
                }
                let res = await this.$http.post("/sys/getHospitalDoctor", {hospitalId: this.hospitalConfig.hospitalId});
                if (res.state) {
                    if (res.data) {
                        this.doctorList = res.data;
                        this.page.total = res.data.totalCount;
                    }
                }
            },
            async editDoctor(row) {
                this.doctor.reset();
                Object.assign(this.doctor,row);
                if(this.doctor.title){
                    this.doctor.title = Number(this.doctor.title);
                }
                this.isShowDoctorDlg = true;
            },
            async updDoctorInfo(){
                let res = await this.$http.post('/sys/updHospitalDoctor', this.doctor);
                if (res.state) {
                    this.$message.success("修改成功");
                    this.isShowDoctorDlg = false;
                    this.load();
                }
            },
            async commit(){
                this.$confirm('确认修改医生信息？', '提示', {}).then(() => {
                    this.updDoctorInfo();
                });
            },
            init() {
                if (this.global.orgId.length) {
                    this.isDisabled = false;
                    this.hospitalConfig.reset();
                    this.getHospitalInfo();
                    this.load()
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            },
        },
        created() {
            this.init()
            /*this.getoptions('hospitalLevel');
            this.getoptions('yesOrNo');
            this.getoptions('title');
            this.getoptions('sex');*/
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 283;
            }
        }
    };
</script>
<style scoped>
    .preImg {
        display: flex;
        align-items: center;
    }

    .avatar-uploader-icon {
        display: inline-block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    .avatar-uploader-icon:hover {
        border-color: #aaa;
    }

    .avatar {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        max-width: 120px;
        display: inline-block;
        vertical-align: middle;
    }
</style>
